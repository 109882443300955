import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import ParagraphComponent from '@core/helpers/paragraph-helpers';
import { isContentTypeHero } from '@core/helpers/hero';

import Breadcrumb from '@shared/breadcrumbs/breadcrumbs';
import ScriptManager from '@shared/script-manager/script-manager';

import { Graphql } from '@models/graphql';
import { Pagination } from '@models/pagination';
import { useMobileL } from '@core/helpers/responsive';

import './landing.scss';

const { default: crumbs } = require('@config/breadcrumbs');

type LandingProps = {
  language: string;
  pagination: Pagination;
  data: {
    landingPage: Graphql;
  };
};

export default function Landing({
  language,
  data,
  pagination,
}: LandingProps): React.ReactElement {
  const { t } = useTranslation();
  const { landingPage } = data;
  const isMobileL = useMobileL();

  if (!landingPage) {
    return <></>;
  }

  const path = landingPage.path?.alias?.replace(`/${landingPage.path?.langcode}/`, '');
  const paragraphs = landingPage?.relationships?.paragraphs || [];

  if (paragraphs.length <= 0) {
    return <></>;
  }

  useEffect(() => {
    if (path === t('talent') || isMobileL) {
      const element = document.querySelector('#fxo-widget') as HTMLElement | null;
      if (element) {
        element.style.display = 'none';
      }
    }
  }, []);

  useEffect(() => {
    if (path === t('talent')) {
      const clientsContainer = document.querySelector('.clients-container') as HTMLElement | null;
      if (clientsContainer) {
        if (window.innerWidth > 1200) {
          clientsContainer.classList.add('offset-6', 'col-6');
        }
        clientsContainer.classList.add('clients-talent-page');
      }
    }
  }, [path]);

  const content = paragraphs.map((node) => ParagraphComponent(
    { ...node, path: landingPage.path, pagination },
    language
  ));

  const hero = isContentTypeHero(paragraphs[0]) ? content.shift() : <></>;
  const backgroundClass
    = landingPage.background === 'talent'
      ? 'page-components-container'
      : 'page-components-container';

  return (
    <>
      {hero}
      {
        (landingPage.path?.alias !== `/${language}`) && !landingPage.breadcrumbHidden && (
          <Breadcrumb
            crumbs={crumbs.concat({ label: landingPage.title, link: '#' })}
            style={landingPage.breadcrumb}
          />
        )
      }
      <div className={backgroundClass}>
        {content}
      </div>

      <ScriptManager
        script={landingPage.script ? landingPage.script : null}
        noscript={landingPage.noscript ? landingPage.noscript : null}
      />
    </>
  );
}

export const query = graphql`
  query ($id: String!) {
    landingPage: nodeLandingPage(id: { eq: $id }) {
      title
      script: field_tag_manager
      breadcrumb: field_breadcrumb_style
      background: field_background
      breadcrumbHidden: field_breadcrumb_hide
      noscript: field_tag_manager_noscript
      robots: field_robots
      keywords: field_keywords
      body {
        processed
        summary
      }
      path {
        alias
        langcode
      }
      relationships {
        paragraphs: field_content {
          type: __typename
          ...CtaParagraph
          ...ListParagraph
          ...BannerPaParagraph
          ...BannerIsoParagraph
          ...BannerQaParagraph
          ...HeroParagraph
          ...HeroVideoParagraph
          ...HeroVideoSliderParagraph
          ...HeroSliderParagraph
          ...HeroFullscreenParagraph
          ...BannerParagraph
          ...BannerTalentParagraph
          ...BannerAdvParagraph
          ...QuoteSliderParagraph
          ...QuoteParagraph
          ...IntroTextParagraph
          ...IntroBlockParagraph
          ...TimelineParagraph
          ...CardsParagraph
          ...BoxesParagraph
          ...GridParagraph
          ...ClientsParagraph
          ...TechnologiesParagraph
          ...TabsParagraph
          ...TableParagraph
          ...WysiwygParagraph
          ...ViewParagraph
          ...FeaturedContentParagraph
          ...BannerFormParagraph
          ...BannerTalentApplyParagraph
          ...EmergencyModalParagraph
          ...BannerVideoParagraph
          ...WebFormParagraph
          ...CollageParagraph
          ...FabParagraph
          ...ImageSliderParagraph
          ...OfficesParagraph
          ...BulletsParagraph
          ...OurGoalsParagraph
          ...SalesContactParagraph
          ...ComponentAiParagraph
          ...ComponentGifParagraph
        }
      }
    }
  }
`;
