import React from 'react';

type ScriptManagerProps = {
  script: string|null;
  noscript: string|null;
}

export default function ScriptManager({
  script, noscript
}: ScriptManagerProps): React.ReactElement {
  if (process.env.GATSBY_DISABLED_GTA === 'true') {
    return <></>;
  }

  return (
    <>
      {script && <script dangerouslySetInnerHTML={{ __html: `${script}` }} />}
      {noscript && <noscript dangerouslySetInnerHTML={{ __html: `${noscript}` }} />}
    </>
  );
}
